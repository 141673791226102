@import "sass/variables.sass";
@import "sass/mixins.sass";

.left-bar {
	position: fixed;
	top: 0;
	left: 0;
	width: 11vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding: 5vh 0 5vh 2.6vw;
	z-index: 12;
	@include xl {
		width: 9vw;
		padding: 3vh 0 3vh 2vw; }
	@include lg {
		width: 13vw;
		padding: 25px 0 25px 25px; }
	@include md {
		width: 0;
		padding: 20px 0 20px 15px; }

	&__logo {
		display: block;
		&:focus {
			outline: none; }
		@include lg {
			min-width: 92px; }
		img {} }

	&__designed {
		color: $c2;
		width: 23px;
		span {
			display: block;
			width: 145px;
			margin-left: -49px;
			transform: rotate(-90deg); }
		@include xxl {
			font-size: 16px;
			span {
				margin-left: -50px; } }
		@include xl {
			font-size: 14px;
			span {
				margin-left: -60px; } }
		@include md {
			display: none; } }

	&__back-btn {
		margin-top: 30px;
		@include md {
			display: none; } } }
