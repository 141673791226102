@import "sass/variables.sass";
@import "sass/mixins.sass";

.project-item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 23.698vw;
	height: 13.334vw;
	margin-left: 7vw;
	margin-bottom: 5.3vw;
	transform-style: preserve-3d;
	transform: perspective(1000px);
	z-index: 1;
	@include xxl {
		width: 25.698vw;
		height: 14.4vw; }
	@include xl {
		width: 322px;
		height: 181px; }
	@include lg {
		width: 335px;
		height: 188px;
		margin-left: 166px;
		margin-bottom: 50px; }
	@include md {
		margin-left: 120px; }
	@include sm {
		width: 280px;
		height: 157px;
		margin-left: 56px;
		margin-bottom: 40px; }
	@include xsm {
		width: 235px;
		height: 132px;
		margin-left: 50px; }

	&:hover {
		.project-item__tool-item {
			color: rgba($c5, .7); } }

	&__img-wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 23.698vw;
		height: 13.334vw;
		background: $c2;
		overflow: hidden;
		z-index: -1;
		@include xxl {
			width: 25.698vw;
			height: 14.4vw; }
		@include xl {
			width: 322px;
			height: 181px; }
		@include lg {
			width: 335px;
			height: 188px; }
		@include sm {
			width: 280px;
			height: 157px; }
		@include xsm {
			width: 235px;
			height: 132px; }
		&:after {
			content: 'Loading ...';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $c5;
			opacity: .5;
			z-index: -1;
			@include md {
				font-size: 16px;
				line-height: 16px;
				font-weight: 400; }
			@include sm {
				font-size: 14px;
				line-height: 14px; } }

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(65deg,rgba(16,9,27,.9), transparent 65%);
			z-index: 1; } }

	&__img {
		img {
			display: block;
			opacity: 0;
			transition: all .4s ease; } }

	.block-reveal {
		display: block; }

	.js-tilt-glare {
		z-index: 2; }

	&__title {
		position: relative;
		color: $c5;
		margin: 0 0 10px -4.9vw;
		transform: translateZ(30px);
		transform-style: preserve-3d;
		@include xl {
			font-size: 28px;
			line-height: 32px; }
		@include lg {
			margin: 0 0 10px -90px; }
		@include sm {
			margin: 0 0 8px -30px; }
		@include xsm {
			font-size: 24px;
			line-height: 28px; }

		& sup {
			font-size: 16px;
			line-height: 20px;
			vertical-align: top;
			@include xl {
				font-size: 14px;
				line-height: 18px; } } }

	&__index {
		position: absolute;
		// top: -38px
		// left: -30px
		// font-size: 64px
		// line-height: 82px
		top: -2vw;
		left: -1.6vw;
		font-size: 3.4vw;
		line-height: 4.4vw;
		font-weight: 900;
		color: $c2;
		opacity: 0.5;
		transform: translateZ(-20px);
		transform-style: preserve-3d;
		z-index: -1;
		@include xl {
			top: -31px;
			left: -23px;
			font-size: 45px;
			line-height: 63px; }
		@include sm {
			top: -30px;
			left: -21px;
			font-size: 40px;
			line-height: 58px;
			font-weight: 700; }
		@include xsm {
			top: -27px;
			left: -14px;
			font-size: 38px;
			line-height: 56px; } }

	&__types {
		display: flex;
		margin-left: -4.9vw;
		margin-bottom: 8px;
		transform: translateZ(30px) translateX(5px);
		transform-style: preserve-3d;
		@include lg {
			margin-left: -90px; }
		@include sm {
			margin-left: -30px;
			margin-bottom: 15px; } }

	&__type-item {
		color: $c1;
		font-size: 17px;
		line-height: 21px;
		margin-right: 8px;
		transition: all .4s ease;
		@include xxl {
			font-size: 14px;
			line-height: 18px; }
		@include sm {
			font-size: 13px;
			line-height: 17px;
			margin-right: 6px;
			&:last-child {
				display: none; } } }

	&__tools {
		display: flex;
		width: 60%;
		margin-left: -4.9vw;
		margin-bottom: 21px;
		transform: translateZ(20px) translateX(2px);
		@include lg {
			margin-left: -90px; }
		@include sm {
			margin-left: -30px;
			margin-bottom: 15px; } }

	&__tool-item {
		color: rgba($c5, .5);
		font-size: 17px;
		line-height: 21px;
		margin-right: 8px;
		transition: all .4s ease;
		@include xxl {
			font-size: 14px;
			line-height: 18px; }
		@include sm {
			font-size: 13px;
			line-height: 17px;
			margin-right: 6px;
			&:last-child {
				display: none; } } }

	&__btn {
		margin-left: -4.9vw;
		transform: translateZ(10px);
		.icon {
			fill: $c1; } } }
