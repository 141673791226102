@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "../../sass/hamburgers/hamburgers.scss";


.right-bar {
	position: fixed;
	top: 0;
	right: 0;
	width: 11vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	padding: 5vh 2.6vw 5vh 0;
	z-index: 11;
	@include xl {
		width: 9vw;
		padding: 3vh 2vw 3vh 0; }
	@include lg {
		width: 13vw;
		padding: 25px 25px 25px 0; }
	@include md {
		width: 13vw;
		padding: 17px 15px 20px 0; } }




.header-layer {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 63px;
	background: rgba($c0, .8);
	opacity: 0;
	visibility: hidden;
	transition: all .4s ease;
	&--visible {
		opacity: 1;
		visibility: visible; } }




////////// Indicator
.section-indicator {
	position: relative;
	height: 25px;
	margin-top: 25px;
	transform: rotate(-90deg) translateY(51px);
	transition: all .6s ease;
	overflow: hidden;
	@include md {
		display: none; }
	&__list {
		transition: all .4s ease; }

	&__item {
		display: block;
		white-space: nowrap;
		font-size: 18px;
		line-height: 20px;
		text-align: center;
		height: 25px;
		@include xxl {
			font-size: 16px;
			line-height: 18px; } } }




////////// Hamburger
.hamburger {
	margin-top: 5px;
	@include xl {
		width: 26px; }
	&:focus {
		outline: none; }
	&-inner,
	&-inner:before,
	&-inner:after {
		transition: all .4s ease !important; }

	&-box {
		@include xl {
			width: 26px; } } }


.hamburger:not(.is-active) {
	.hamburger-inner {
		@include xl {
			width: 26px; }
		&:before {
			width: 18px;
			left: auto;
			right: 0;
			@include xl {
				width: 16px; } }

		&:after {
			width: 24px;
			left: auto;
			right: 0;
			@include xl {
				width: 21px; } } }

	&:hover {
		.hamburger-inner {
			width: 30px;
			@include xl {
				width: 26px; }
			&:before,
			&:after {
				width: 30px;
				@include xl {
					width: 26px; } } } } }


////////// Socs
.socs {
	@include md {
		display: none; }
	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: -7px; }
	&__item {
		margin-bottom: 7px; }
	&__link {
		font-size: 24px;
		line-height: 24px;
		color: $c5;
		transition: all .4s ease;
		&:hover {
			color: $c1; }
		@include xxl {
			font-size: 22px;
			line-height: 22px; }
		@include xl {
			font-size: 20px;
			line-height: 20px; } } }
