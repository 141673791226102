@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "bootstrap/scss/mixins/_grid.scss";

.recently-projects {
	padding: 105px 0;
	@include xxl {
		padding: 80px 0; }
	@include xl {
		padding: 50px 0; }

	&__title {
		margin-bottom: 120px;
		@include xl {
			margin-bottom: 90px; }
		@include lg {
			margin-bottom: 80px; }
		@include md {
			margin-bottom: 50px; }

		& sup {
			position: absolute;
			top: 10px;
			font-size: 16px;
			line-height: 20px;
			font-weight: 400;
			color: $c3;
			margin-left: 10px;
			@include md {
				top: auto;
				bottom: -16px;
				left: 50%;
				font-size: 14px;
				line-height: 18px;
				transform: translateX(-50%); } } }

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px; }

	&__col {
		@include make-col(6);
		padding-left: 20px;
		padding-right: 20px;
		@include lg {
			@include make-col(12); }
		@include md {
			display: flex;
			justify-content: center; }

		&--offset {
			display: flex;
			justify-content: flex-end;
			@include xl {
				justify-content: flex-start; }
			@include md {
				justify-content: center; }
			.project-item {
				// +xxl
				// 	margin-right: 0.2vw
				// +xl
 } } }				// 	margin-right: auto

	&__bottom {
		text-align: center; }

	&__btn {
		margin-top: 40px;
		.icon {
			fill: $c1;
			margin-right: 10px;
			transform: rotate(90deg); }
		&:hover,
		&:focus {
			.icon {
				transform: rotate(90deg) translateX(4px); } } }

	.spinner-container {
		height: 300px; } }
