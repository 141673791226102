@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "bootstrap/scss/mixins/_grid.scss";

.skills {
	padding: 110px 0 30px;
	overflow: hidden;
	@include xxl {
		padding: 80px 0 20px; }
	@include sm {
		padding: 70px 0 20px; }

	&__container {
		max-width: 1010px;
		margin: 0 auto;
		@include xxl {
			max-width: 934px; }
		@include xl {
			max-width: 828px; }
		@include lg {
			max-width: 548px; }
		@include sm {
			max-width: 100%;
			padding-left: 15px;
			padding-right: 15px; } }

	&__title {
		margin-bottom: 120px;
		@include xl {
			margin-bottom: 90px; }
		@include lg {
			margin-bottom: 70px; }
		@include md {
			margin-bottom: 50px; } }

	&__row {
		@include make-row();
		justify-content: center;
		margin-left: -40px;
		margin-right: -40px;
		@include lg {
			margin-left: -20px;
			margin-right: -20px; }
		@include md {
			margin-left: -12px;
			margin-right: -12px; }
		@include xsm {
			margin-left: -20px;
			margin-right: -20px; } }

	&__col {
		flex: 1 1 20%;
		max-width: 20%;
		padding-left: 40px;
		padding-right: 40px;
		@include lg {
			@include make-col(3);
			padding-left: 20px;
			padding-right: 20px; }
		@include md {
			@include make-col(4);
			padding-left: 12px;
			padding-right: 12px; }
		@include xsm {
			@include make-col(6);
			padding-left: 20px;
			padding-right: 20px; } }

	&__divider {
		position: relative;
		width: 100%;
		height: 2px;
		margin: 10px 0 70px;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			width: 100px;
			height: 2px;
			transform: translateX(-50%);
			background-color: $c2; } } }

.skill-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 70px;
	transition: all .6s ease;
	@include xl {
		margin-bottom: 35px; }
	@include lg {
		max-width: 120px;
		margin: 0 auto 25px; }
	@include sm {
		margin: 0 auto 10px; }
	@include xsm {
		max-width: 110px; }

	&--small {
		.skill-item {
			&__img-wrap {
				height: 45px;
				margin-bottom: 20px;
				@include xxl {
					height: 40px; }
				@include lg {
					height: 35px; }
				@include md {
					height: 30px;
					margin-bottom: 15px; }
				@include xsm {
					height: 30px; } }

			&__progress {
				width: 80%; } } }

	// Animation on scroll
	&.init {
		opacity: 0;
		transform: scale(0.6) translateY(30px); }

	&.out {
		.skill-item__progress {
			.progress__inner {
				transform: translateX(0) !important; } } }

	&.in {
		opacity: 1;
		transform: scale(1) translateY(0);
		.skill-item__progress {} }

	// Animation on hover
	&:hover {
		transform: translateY(-4px);
		.skill-item {
			&__title {
				opacity: 1;
				transform: translateY(-6px); }
			&__progress {
				background: rgba($c2, .8);
				transform: translateY(-4px) scale(1.05); } } }

	&__img-wrap {
		height: 80px;
		margin-bottom: 20px;
		@include xxl {
			height: 75px; }
		@include xl {
			height: 70px;
			margin-bottom: 22px; }
		@include lg {
			height: 55px; }
		@include md {
			height: 50px;
			margin-bottom: 17px; }
		@include xsm {
			height: 45px; }

		img {
			display: block;
			width: auto;
			height: 100%;
			margin: 0 auto; } }

	&__title {
		text-align: center;
		opacity: .7;
		transition: all .6s ease;
		@include xl {
			font-size: 16px;
			line-height: 20px; } }

	&__progress {
		margin-bottom: 10px; } }

.progress {
	width: 100%;
	height: 4px;
	border-radius: 4px;
	background: rgba($c2, .4);
	transition: all .6s ease;
	overflow: hidden;
	@include md {
		height: 3px; }
	&__inner {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background: $c1;
		margin-left: -100%;
		box-shadow: 3px 0 5px rgba(#000, .3);
		transition: all 1s ease .2s; } }
