@import "sass/variables.sass";
@import "sass/mixins.sass";

// @import "bootstrap/scss/bootstrap-reboot.scss"
// @import "bootstrap/scss/bootstrap-grid.scss"
@import "bootstrap/scss/mixins/_grid.scss";


////////// Fonts
@include font-face("FuturaPT", "./../fonts/FuturaPT-Book/FuturaPT-Book", normal, normal);
@include font-face("FuturaPT", "./../fonts/FuturaPT-Medium/FuturaPT-Medium", 500, normal);
@include font-face("FuturaPT", "./../fonts/FuturaPT-Demi/FuturaPT-Demi", 600, normal);
@include font-face("FuturaPT", "./../fonts/FuturaPT-Bold/FuturaPT-Bold", 700, normal);
@include font-face("FuturaPT", "./../fonts/FuturaPT-ExtraBold/FuturaPT-ExtraBold", 900, normal);


section {
	overflow: hidden; }

////////// Reset & Base
*,
*:before,
*:after {
	box-sizing: border-box; }

html {
	height: 100vh;
	font-family: sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
	position: relative;
	min-width: 320px;
	// height: 100vh
	font-family: $def_font;
	@include def_text;
	color: $c5;
	margin: 0;
	background: $c0;
	overflow: hidden;
	z-index: 1;
	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(../../img/def_bg.jpg) no-repeat center center;
		background-size: cover;
		opacity: 0.4;
		z-index: -1; }
	&.is-load {
		overflow: auto; } }

button {
	font-family: $def_font;
	padding: 0;
	border: none;
	background: none; }

img {
	max-width: 100%;
	height: auto; }



////////// Grid
.container {
	margin: 0 11vw;
	// border: 1px solid red
	@include xl {
		margin: 0 9.5vw; }
	@include lg {
		max-width: 550px;
		margin: 0 auto; }
	@include sm {
		max-width: 100%;
		margin: 0 15px; } }

.bg-lines {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	padding-left: 11vw;
	padding-right: 11vw;
	opacity: .15;
	z-index: -1;
	@include xl {
		padding-left: 9.5vw;
		padding-right: 9.5vw; }
	@include lg {
		left: 50%;
		max-width: 550px;
		padding: 0;
		transform: translateX(-50%); }
	@include sm {
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px; }

	div {
		width: 1px;
		height: 100%;
		&:nth-child(1),
		&:nth-child(3) {
			@include gradient-y($c5, transparent); }
		&:nth-child(2) {
			@include gradient-y(transparent, $c5); } } }




////////// Page layout
.page-layout {
	// padding: 240px 0 0
	[class*="__title"] {
		margin-bottom: 90px;
		@include xxl {
			margin-bottom: 70px; }
		@include xl {
			margin-bottom: 50px; }
		@include lg {
			margin-bottom: 40px; } } }



////////// Typography
h1 {
	font-size: 3.34vw; // 64px
	line-height: 4.26vw; // 82px
	font-weight: 600;
	@include xl {
		font-size: 39px;
		line-height: 46px; }
	@include lg {
		font-size: 32px;
		line-height: 37px; }
	@include sm {
		font-size: 7.2vw;
		line-height: 8.7vw; } }

h2 {
	font-size: 64px;
	line-height: 82px;
	font-weight: 700;
	@include xxl {
		font-size: 58px;
		line-height: 76px; }
	@include xl {
		font-size: 46px;
		line-height: 64px;
		font-weight: 600; }
	@include lg {
		font-size: 37px;
		line-height: 55px; }
	@include md {
		font-size: 35px;
		line-height: 53px; }
	@include xsm {
		font-size: 32px;
		line-height: 50px; }

	&[class*="__title"] {
		position: relative;
		text-align: center;
		z-index: 1;
		&:before {
			content: attr(data-title);
			position: absolute;
			bottom: -5px;
			left: 50%;
			font-size: 144px;
			line-height: 144px;
			color: rgba($c2, .1);
			white-space: nowrap;
			transform: translateX(-50%);
			z-index: -1;
			@include xxl {
				font-size: 120px;
				line-height: 120px; }
			@include xl {
				bottom: -2px;
				font-size: 88px;
				line-height: 91px;
				font-weight: 700; }
			@include lg {
				bottom: 2px;
				font-size: 71px;
				line-height: 73px;
				font-weight: 600; }
			@include md {
				bottom: 1px;
				font-size: 66px;
				line-height: 68px; }
			@include xsm {
				bottom: 2px;
				font-size: 71px;
				line-height: 73px; } } } }


h3 {
	font-size: 36px;
	line-height: 46px;
	font-weight: 500;
	@include xxxl {
		font-size: 33px;
		line-height: 37px; }
	@include xxl {
		font-size: 30px;
		line-height: 34px; }
	@include xl {
		font-size: 22px;
		line-height: 26px; }
	@include xsm {
		font-size: 21px;
		line-height: 25px; } }

h4 {
	font-size: 24px;
	line-height: 31px;
	font-weight: 700;
	margin: 40px 0 15px;
	@include xl {
		font-size: 22px;
		line-height: 29px;
		margin: 35px 0 10px; }
	@include lg {
		font-size: 20px;
		line-height: 27px;
		margin: 30px 0 10px; }
	@include sm {
		font-size: 18px;
		line-height: 25px;
		margin: 30px 0 10px; } }

ul {
	list-style: none;
	margin: 0;
	padding: 0; }

a {
	text-decoration: none; }




////////// Common
.page-transition {
	position: fixed;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: rgba($c5, .6);
	pointer-events: none;
	transition: all .4s ease;
	z-index: 100;
	&.active {
		left: 0; } }

// main
// 	position: absolute
// 	top: 0
// 	left: 0
// 	opacity: 0

.page {
	&-exit {
		opacity: 1; }
	&-exit-active {
		opacity: 0;
		transition: all .6s; }

	&-enter {
		opacity: 0; }
	&-enter-active {
		opacity: 1;
		transition: all .6s; } }


.scroll-btn {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&:hover,
	&:focus {
		cursor: pointer;
		outline: none;
		.scroll-btn__dot {
				background: $c1; } }
	&:active {
		.scroll-btn {
			&__text {
				color: $c1; } } }

	&__icon {
		width: 19px;
		height: 12px;
		@include xl {
			width: 16px;
			height: 4px; } }
	&__dot {
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background: $c5;
		transition: background .6s ease;
		@include xl {
			width: 6px;
			height: 6px; } }
	&__text {
		position: relative;
		font-size: 14px;
		line-height: 18px;
		font-weight: 500;
		color: $c3;
		// transition: all .1s ease
		user-select: none;
		@include xl {
			font-size: 12px;
			line-height: 16px; }
		&-overlay {
			position: absolute;
			top: 0;
			left: 0;
			color: $c1; } }

	// Mods
	&--totop {
		@include xl {
			margin-left: -8px; }
		@include md {
			display: none; }
		.scroll-btn {
			&__icon {
				margin-bottom: 10px;
				transition: all .6s ease;
				transition-delay: .2s;
				@include xl {
					margin-bottom: 14px; } }
			&__dot {
				margin-bottom: 10px;
				transition: all .6s ease;
				@include xl {
					margin-bottom: 7px; } } }

		&:hover,
		&:focus {
			.scroll-btn {
				&__icon {
					transform: translateY(-5px); }
				&__dot {
					transform: translateY(-3px); } } } } }



// Button
.btn {
	display: inline-flex;
	align-items: center;
	font-size: 24px;
	line-height: 24px;
	color: #fff;
	transition: all .7s ease;
	@include xxl {
		font-size: 20px;
		line-height: 24px; }
	@include xl {
		font-size: 18px;
		line-height: 27px; }
	.icon {
		width: 31px;
		height: 17px;
		fill: $c1;
		transition: all .7s ease;
		&--left {
			margin-right: 20px; }
		&--right {
			margin-left: 20px; }
		@include xl {
			width: 28px;
			height: 21px;
			&--left {
				margin-right: 15px; }
			&--right {
				margin-left: 15px; } }

		title {
			display: none;
			pointer-events: none; } }

	&:hover,
	&:focus {
		outline: none;
		text-decoration: none;
		.icon {
			&--arrow-left {
				transform: translateX(-5px); }
			&--arrow-right {
				transform: translateX(5px); } } }

	&:active {
		color: $c1; }


	&--back {
		font-size: 18px;
		line-height: 18px;
		@include xl {
			font-size: 16px;
			line-height: 27px; }
		.icon {
			&--arrow-left {
				margin-right: 15px; } } } }
