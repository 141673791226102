@import "sass/variables.sass";
@import "sass/mixins.sass";

.footer {
	padding: 30px 0 70px;
	@include xxl {
		padding: 20px 0 60px; }
	@include xl {
		padding: 15px 0 40px; }
	@include sm {
		padding: 10px 0 35px; }

	&__copyright {
		width: 100%;
		color: $c2;
		text-align: center;
		@include md {
			font-size: 16px;
			line-height: 22px; } } }
