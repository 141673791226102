@import "sass/variables.sass";
@import "sass/mixins.sass";

.project {
	position: relative;
	// padding-top: 200px
	// height: 100vh
	@include lg {
		padding-top: 130px;
		padding-bottom: 100px; }
	@include md {
		padding-bottom: 0; }
	@include sm {
		padding-top: 110px; }
	@include xsm {
		padding-top: 100px; }

	&__row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 755px;
		height: 100vh;
		@include xxxl {
			max-height: 900px; }
		@include xl {
			min-height: 540px;
			max-height: 768px; }
		@include lg {
			max-height: none;
			height: auto; } }

	&__col {
		&:nth-child(1) {
			flex: 1 1 33%;
			max-width: 33%;
			padding-right: 2vw;
			@include xxl {
				flex: 1 1 37%;
				max-width: 37%;
				padding-right: 0; }
			@include lg {
				flex: 1 1 100%;
				max-width: 100%; } }

		&:nth-child(2) {
			flex: 1 1 67%;
			max-width: 67%;
			text-align: right;
			@include xxl {
				flex: 1 1 63%;
				max-width: 63%; }
			@include lg {
				flex: 1 1 100%;
				max-width: 100%;
				order: -1; } } }

	&__title {
		font-size: 44px;
		line-height: 50px;
		font-weight: 700;
		margin-bottom: 8px !important;
		@include xxxl {
			font-size: 40px;
			line-height: 46px; }
		@include xxl {
			font-size: 36px;
			line-height: 42px; }
		@include xl {
			font-size: 28px;
			line-height: 34px; }
		@include sm {
			font-size: 26px;
			line-height: 32px; }
		@include xsm {
			font-size: 24px;
			line-height: 30px; }

		& sup {
			font-size: 18px;
			line-height: 22px;
			vertical-align: top;
			@include lg {
				font-size: 16px;
				line-height: 20px; } } }

	&__types {
		color: $c2;
		margin-bottom: 24px;
		@include xxl {
			margin-bottom: 17px; }
		@include xl {
			font-size: 16px;
			line-height: 20px; }
		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			li {
				margin-right: 10px;
				&:last-child {
					margin-right: 0; } } } }
	&__desc {
		margin-bottom: 33px;
		@include xxl {
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 22px; }
		@include xl {
			font-weight: normal; } }

	&__item {
		margin-bottom: 40px;
		@include xxl {
			margin-bottom: 23px; }
		@include xl {
			margin-bottom: 16px; }
		&-title {
			font-size: 24px;
			line-height: 31px;
			font-weight: 700;
			color: $c1;
			margin-bottom: 7px;
			@include xxl {
				font-size: 20px;
				line-height: 27px;
				margin-bottom: 0; }
			@include xl {
				font-size: 18px;
				line-height: 25px; } }
		&-value {
			@include xxl {
				font-size: 16px;
				line-height: 24px; }
			@include xl {
				font-weight: normal; }
			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				li {
					margin-right: 10px;
					&:last-child {
						margin-right: 0; } } } } }

	&__btn {
		margin-top: 40px;
		@include xxl {
			margin-top: 20px; } }

	&__img {
		position: relative;
		display: flex;
		justify-content: flex-end;
		padding-right: 3vw;
		transform-style: preserve-3d;
		transform: perspective(1000px);
		@include xxl {
			padding-right: 0; }
		@include lg {
			margin-bottom: 50px;
			transform: perspective(1000px) rotateX(-4.56deg) rotateY(4.66deg) scale3d(1, 1, 1);
			will-change: transform;
			animation: project-img-tilt 6s ease-in-out 1s infinite; }
		@include md {
			justify-content: flex-start; }
		&:hover {
			.project__img-main,
			.project__img-mobile {
				box-shadow: 2px 20px 40px rgba($c2, .3); } }
		&-main,
		&-mobile {
			position: relative;
			border: 6px solid $c2;
			background: darken($c2, 9%);
			box-shadow: 2px 10px 20px rgba($c2, .1);
			transition: all .4s ease;
			overflow: hidden;
			z-index: 1;
			@include xl {
				border-width: 5px; }
			@include md {
				border-width: 4px; }
			&:after {
				content: 'Loading ...';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: .5;
				z-index: -1;
				@include md {
					font-size: 16px;
					line-height: 16px;
					font-weight: 400; }
				@include sm {
					font-size: 14px;
					line-height: 14px; } } }
		&-main {
			width: 47.136vw;
			height: 26.771vw;
			max-width: 905px;
			max-height: 514px;
			border-radius: 30px;
			@include xl {
				border-radius: 20px; }
			@include lg {
				width: 550px;
				height: 309px; }
			@include md {
				width: 510px;
				height: 287px;
				border-radius: 15px; }
			@include sm {
				width: 88vw;
				height: 49.9vw; }
			img {
				border-radius: 24px;
				@include xl {
					border-radius: 13px; }
				@include md {
					border-radius: 10px; } } }
		&-mobile {
			position: absolute;
			bottom: -61px;
			right: 13px;
			width: 11.094vw;
			height: 19.1vw;
			max-width: 213px;
			max-height: 367px;
			border-radius: 20px;
			transform: translateZ(30px) scale(0.97);
			@include xxl {
				height: 19vw;
				right: -23px; }
			@include xl {
				height: 18.79vw;
				border-radius: 12px; }
			@include lg {
				bottom: -25px;
				width: 130px;
				height: 223px; }
			@include md {
				right: 6px;
				width: 120px;
				height: 205px;
				border-radius: 9px; }
			@include sm {
				width: 22vw;
				height: 37.6vw; }

			&:after {
				@include xsm {
					font-size: 12px;
					line-height: 12px; } }

			img {
				border-radius: 14px;
				@include xl {
					border-radius: 5px; }
				@include md {
					border-radius: 2px; } } }

		img {
			display: block;
			opacity: 0;
			transition: all .4s ease;
			z-index: -1; }

		&--mobile {
			.project {
				&__img {
					&-main {
						border: none;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover; } } } } } }

	.scroll-btn {
		@include md {
			display: none; } } }

@keyframes project-img-tilt {
	0% {
		transform: perspective(1000px) rotateX(-4.56deg) rotateY(4.66deg) scale3d(1, 1, 1);
		will-change: transform; }
	25% {
		transform: perspective(1000px) rotateX(-4.28deg) rotateY(-4.2deg) scale3d(1, 1, 1);
		will-change: transform; }
	50% {
		transform: perspective(1000px) rotateX(5deg) rotateY(-4.53deg) scale3d(1, 1, 1);
		will-change: transform; }
	75% {
		transform: perspective(1000px) rotateX(4.7deg) rotateY(4.74deg) scale3d(1, 1, 1);
		will-change: transform; }
	100% {
		transform: perspective(1000px) rotateX(-4.56deg) rotateY(4.66deg) scale3d(1, 1, 1);
		will-change: transform; } }
