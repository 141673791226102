@import "sass/variables.sass";
@import "sass/mixins.sass";

.project-add-imgs {
	padding: 120px 0 60px;
	@include xl {
		padding: 100px 0 50px; }
	@include sm {
		padding: 100px 0 40px; }

	&__img {
		position: relative;
		max-width: 90%;
		margin: 0 auto 60px;
		background: darken($c2, 9%);
		border-radius: 20px;
		border: 6px solid $c2;
		box-shadow: 2px 10px 20px rgba($c2, .1);
		transition: all .4s ease;
		overflow: hidden;
		z-index: 1;
		@include xl {
			border-width: 5px;
			border-radius: 20px; }
		@include lg {
			margin: 0 auto 40px; }
		@include md {
			border-width: 4px;
			border-radius: 15px; }
		@include sm {
			max-width: 100%;
			margin: 0 auto 30px; }
		&:after {
			content: 'Loading ...';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: .5;
			z-index: -1; }
		&:hover {
			transform: translateY(-2px);
			box-shadow: 2px 20px 40px rgba($c2, .5); }
		img {
			display: block;
			border-radius: 14px;
			transition: all .4s ease;
			opacity: 0;
			@include xl {
				border-radius: 14px; }
			@include md {
				border-radius: 10px; } } } }
