@import "sass/variables.sass";
@import "sass/mixins.sass";

.not-found {
	&__row {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding-top: 17vh;
		@include height_fill_with_footer;
		&::before {
			content: '404';
			position: absolute;
			top: 48.5%;
			left: 50%;
			font-size: 26vw;
			line-height: 26vw;
			font-weight: 700;
			color: rgba($c5, .03);
			transform: translate(-50%,-50%);
			pointer-events: none;
			z-index: -1;
			@include lg {
				top: 47%;
				font-size: 280px;
				line-height: 280px; }
			@include sm {
				top: 44.5%;
				font-size: 180px;
				line-height: 180px; }
			@include xsm {
				top: 43.5%;
				font-size: 150px;
				line-height: 150px; } } }

	&__block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center; }

	&__title {
		display: flex;
		align-items: center;
		font-size: 110px;
		line-height: 110px;
		font-weight: 700;
		margin: 0 auto 20px;
		@include xxxl {
			font-size: 90px;
			line-height: 90px; }
		@include xxl {
			font-size: 80px;
			line-height: 80px; }
		@include sm {
			font-size: 62px;
			line-height: 62px;
			margin-bottom: 10px; }
		@include xsm {
			font-size: 58px;
			line-height: 58px;
			margin-bottom: 10px; } }

	&__desc {
		display: flex;
		align-items: center;
		font-size: 22px;
		line-height: 28px;
		margin-bottom: 120px;
		@include xxl {
			font-size: 20px;
			line-height: 26px; }
		@include lg {
			margin-bottom: 90px; }
		@include sm {
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 70px; } }

	&__emoji {
		position: relative;
		width: 27px;
		height: 27px;
		margin-left: 3px;
		overflow: hidden;
		&-inner {} }

	&__btn {
		font-size: 21px;
		line-height: 21px;
		@include xl {
			font-size: 18px;
			line-height: 25px; }
		@include xsm {
			font-size: 16px; } } }
