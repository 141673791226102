@import "sass/variables.sass";
@import "sass/mixins.sass";

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: $c0;
	pointer-events: none;
	z-index: 20;

	svg {
		width: 180px !important;
		height: auto !important;
		margin-left: 10px;
		margin-bottom: 40px;
		@include xxl {
			width: 150px !important;
			margin-bottom: 30px; }
		@include md {
			width: 140px !important;
			margin-bottom: 25px; }
		@include sm {
			width: 130px !important; }
		@include xsm {
			width: 110px !important;
			margin-bottom: 17px; } }

	&__progress {
		position: relative;
		width: 220px;
		height: 3px;
		border-radius: 3px;
		background: $c2;
		overflow: hidden;
		@include xxl {
			width: 195px; }
		@include md {
			width: 180px; }
		@include sm {
			width: 144px; }
		&-line {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translate3d(-100%, 0px, 0px);
			border-radius: 3px;
			background: $c1; } } }
