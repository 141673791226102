@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "bootstrap/scss/mixins/_grid.scss";

.work-experience {
	padding: 77px 0 110px;
	@include xxl {
		padding: 60px 0 90px; }
	@include sm {
		padding: 80px 0 80px; }

	&__title {
		margin-bottom: 110px;
		@include xl {
			margin-bottom: 80px; }
		@include lg {
			margin-bottom: 70px; }
		@include md {
			margin-bottom: 50px; } }

	&__container {
		// +lg
 }		// 	max-width: 500px
	&__row {} }

// Timeline
.timeline {
	display: flex;
	flex-wrap: wrap;
	@include md {
		padding-left: 30px; } }

.timeline-item {
	position: relative;
	@include make-col(6);
	display: flex;
	flex-direction: column;
	padding: 34px 0;
	z-index: 1;
	@include xl {
		padding: 25px 0; }
	@include md {
		@include make-col(12);
		padding: 19px 0; }

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		width: 20px;
		height: 20px;
		border: 2px solid $c1;
		border-radius: 50%;
		transform: translateY(-50%);
		background: lighten($c0, 3%);
		transition: background .4s ease, transform .4s cubic-bezier(0.18, 0.89, 0.48, 2.01);
		z-index: -1;
		@include xl {
			width: 18px;
			height: 18px; } }
	&:after {
		content: '';
		position: absolute;
		top: 0;
		width: 2px;
		height: 100%;
		background: $c2;
		z-index: -2; }

	&__date {
		font-size: 18px;
		line-height: 23px;
		color: $c2;
		margin-bottom: 5px;
		transition: all .6s ease;
		@include xl {
			font-size: 16px;
			line-height: 21px; }
		@include xsm {
			font-size: 14px;
			line-height: 19px; } }

	&__title {
		color: $c1;
		margin: 0 0 10px;
		transition: all .6s ease .1s; }

	&__company {
		position: relative;
		font-size: 20px;
		line-height: 26px;
		padding-left: 17px;
		transition: all .6s ease .17s;
		&:before {
			content: '';
			position: absolute;
			top: 11px;
			left: 0;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: $c2; }
		@include xxl {
			font-size: 18px;
			line-height: 24px; }
		@include xl {
			font-size: 16px;
			line-height: 22px;
			padding-left: 15px;
			&:before {
				top: 9px;
				width: 6px;
				height: 6px; } }
		@include xsm {
			font-size: 14px;
			line-height: 20px;
			padding-left: 12px;
			&:before {
				top: 8px;
				width: 5px;
				height: 5px; } } }

	&__start-dot {
		position: absolute;
		bottom: -8px;
		width: 12px;
		height: 12px;
		background: $c1;
		border-radius: 50%; }

	&__start-line {
		position: absolute;
		top: 50%;
		width: 2px;
		height: 50%;
		@include gradient_y($c2, $c1);
		z-index: -2; }

	&__last-line {
		position: absolute;
		bottom: 50%;
		left: -1px;
		width: 2px;
		height: 50%;
		@include gradient_y(transparent, $c2);
		z-index: -2; }

	&:first-child {
		&:before {
			background: $c1; }
		&:after {
			top: auto;
			bottom: 0;
			height: 50%; } }

	&:last-child {
		&:after {
			height: 50%; } }

	&:nth-child(odd) {
		@include make-col-offset(6);
		padding-left: 50px;
		@include xxl {
			padding-left: 40px; }
		@include xl {
			padding-left: 35px; }
		@include md {
			padding-left: 40px;
			@include make-col-offset(0); }
		@include xsm {
			padding-left: 30px; }

		&:before {
			left: -10px;
			@include xl {
				left: -9px; } }

		&:after {
			left: -1px; }

		.timeline-item__start-dot {
			left: -6px; }
		.timeline-item__start-line {
			left: -1px; } }


	&:nth-child(even) {
		align-items: flex-end;
		text-align: right;
		padding-right: 50px;
		@include xxl {
			padding-right: 40px; }
		@include xl {
			padding-right: 35px; }
		@include md {
			padding-right: 0;
			padding-left: 40px;
			align-items: flex-start;
			text-align: left; }
		@include xsm {
			padding-left: 30px; }

		&:before {
			right: -10px;
			@include xl {
				right: -9px; }
			@include md {
				right: auto;
				left: -9px; } }

		&:after {
			right: -1px;
			@include md {
				right: auto;
				left: -1px; } }

		.timeline-item__start-dot {
			right: -6px; }
		.timeline-item__start-line {
			right: -1px; } }

	// Animation on scroll
	&.init {
		transition: all .4s ease;
		opacity: 0;
		&:nth-child(odd) {
			.timeline-item {
				&__date,
				&__title,
				&__company {
					opacity: 0;
					transform: translateX(-50px); } } }
		&:nth-child(even) {
			.timeline-item {
				&__date,
				&__title,
				&__company {
					opacity: 0;
					transform: translateX(50px);
					@include md {
						transform: translateX(-50px); } } } } }

	&.in {
		opacity: 1;
		&:nth-child(odd) {
			.timeline-item {
				&__date,
				&__title,
				&__company {
					opacity: 1;
					transform: translateX(0); } } }
		&:nth-child(even) {
			.timeline-item {
				&__date,
				&__title,
				&__company {
					opacity: 1;
					transform: translateX(0); } } } }

	// Animation on hover
	&:hover {
		&:before {
			background: $c1;
			transform: translateY(-50%) scale(1.1); }
		&:nth-child(odd) {
			.timeline-item {
				&__date,
				&__title,
				&__company {
					transform: translateX(-7px);
					@include md {
						transform: translateX(-7px); } } } }
		&:nth-child(even) {
			.timeline-item {
				&__date,
				&__title,
				&__company {
					transform: translateX(7px);
					@include md {
						transform: translateX(-7px); } } } } } }
