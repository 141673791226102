@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "bootstrap/scss/mixins/_grid.scss";


.contacts {
	padding: 60px 0 130px;
	@include xl {
		padding: 40px 0 100px; }
	@include lg {
		padding: 40px 0 80px; }
	@include sm {
		padding: 40px 0 60px; }

	&__row {
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center; }

	&__title {
		margin-bottom: 40px;
		@include lg {
			margin-bottom: 30px; }
		@include md {
			margin-bottom: 20px; }
		@include sm {
			margin-bottom: 10px; } }

	&__subtitle {
		margin-bottom: 20px;
		color: $c1;
		@include lg {
			margin-bottom: 15px; }
		@include md {
			margin-bottom: 12px; } }

	&__list {
		display: flex;
		align-items: center; }

	&__item {
		margin-right: 25px;
		@include md {
			margin-right: 22px; }
		@include xsm {
			margin-right: 15px; }
		&:last-child {
			margin-right: 0; } }

	&__link {
		display: block;
		font-size: 35px;
		line-height: 35px;
		color: $c5;
		transition: all .6s ease;
		@include xl {
			font-size: 28px;
			line-height: 28px; }
		@include lg {
			font-size: 27px;
			line-height: 27px; }
		&:hover {
			color: $c1;
			transform: translateY(-4px); } } }
