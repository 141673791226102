@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "bootstrap/scss/mixins/_grid.scss";


.about {
	padding: 100px 0 60px;
	@include xl {
		padding: 70px 0 40px; }

	&__title {
		@include lg {
			margin-bottom: 30px; }
		@include md {
			margin-bottom: 22px; } }

	&__content {
		max-width: 1140px;
		margin: 0 auto;
		font-size: 24px;
		line-height: 33px;
		font-weight: 400;
		color: rgba(#fff, .9);
		@include xl {
			font-size: 22px;
			line-height: 29px; }
		@include lg {
			font-size: 20px;
			line-height: 25px; }
		@include md {
			font-size: 18px;
			line-height: 23px; }
		@include xsm {
			font-size: 16px;
			line-height: 21px; }

		h4 {
			color: $c1; }

		ul {
			padding-left: 14px;
			li {
				position: relative;
				padding-left: 23px;
				margin-bottom: 8px;
				@include md {
					margin-bottom: 10px; }
				&:before {
					content: '';
					position: absolute;
					top: 15px;
					left: 0;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background: $c1;
					@include lg {
						top: 10px; }
					@include md {
						top: 9px; }
					@include sm {
						top: 8px; } } } }

		strong {
			font-weight: 600; }

		span {
			color: $c1; }

		a {
			position: relative;
			color: $c1;
			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background: $c1;
				transition: all .6s ease; }
			&:hover {
				&:before {
					width: 100%; } } } } }
