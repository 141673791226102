@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "bootstrap/scss/mixins/_grid.scss";

.main {
	position: relative;
	height: 100vh;
	@include lg {
		height: auto;
		padding: 100px 0 35px;
		text-align: center; }
	@include sm {
		padding: 80px 0 25px; }
	&__row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 100vh;
		padding-bottom: 50px;
		@include lg {
			height: auto; } }
	&__col {
		&:nth-child(1) {
			@include make-col(7);
			@include lg {
				@include make-col(12); } }
		&:nth-child(2) {
			@include make-col(5);
			display: flex;
			justify-content: flex-end;
			@include lg {
				@include make-col(12);
				order: -1;
				justify-content: center; } } }

	&__title {
		margin-bottom: 1.2vh;
		@include lg {
			margin-top: 0;
			margin-bottom: 6px; }
		@include md {
			margin-bottom: 0; }
		span {
			color: $c1;
			line-height: 4.9vw; } }

	&__subtitle {
		font-size: 1.87vw; // 36px
		line-height: 2.2vw; // 46px
		margin-top: 0;
		margin-bottom: 6.9vh;
		@include xl {
			font-size: 22px;
			line-height: 26px; }
		@include lg {
			margin-bottom: 45px; }
		@include sm {
			font-size: 4.5vw;
			margin-bottom: 30px; }
		@include xsm {
			margin-bottom: 22px; }
		span {
			color: $c1; } }

	&__lets {
		font-size: 1.14vw; // 22px
		font-weight: 500;
		color: $c3;
		outline: none;
		transition: all .4s ease;
		&:hover {
			color: $c1; }
		@include xl {
			font-size: 16px; }
		@include sm {
			margin-bottom: 9px; }
		@include xsm {
			margin-bottom: 0; } }

	&__img-wrap {
		position: relative;
		width: 26.042vw;
		height: 26.042vw;
		margin-right: 2.08331vw;
		border-radius: 50%;
		transform-style: preserve-3d;
		box-shadow: 5px 5px 20px rgba(0,0,0, .2);
		perspective: 1000px;
		transform: perspective(1000px);
		background: $c2;
		z-index: 1;
		@include xl {
			width: 290px;
			height: 290px; }
		@include lg {
			width: 270px;
			height: 270px;
			margin-right: 20px;
			margin-bottom: 70px;
			transform: perspective(1000px) rotateX(0deg) rotateY(-5.64deg) scale3d(1, 1, 1);
			will-change: transform;
			animation: avatar_animation 2.2s ease-in-out infinite 2s alternate; }
		@include sm {
			width: 250px;
			height: 250px;
			margin-bottom: 50px; }
		@include xsm {
			width: 210px;
			height: 210px;
			margin-right: 10px;
			margin-bottom: 40px; }
		img {
			border-radius: 50%;
			opacity: 0;
			transition: all .4s ease;
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition-delay: .1s; }
			&:nth-child(2) {
				transform: translateZ(30px) scale(0.94); }
			&:nth-child(3),
			&:nth-child(4) {
				transform: translateZ(10px) scale(0.98); }
			&:nth-child(4) {
				@include lg {
					display: none; } } }

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: $c1;
			transform: translate(2.08331vw,2.08331vw) translateZ(-2.08331vw) scale(1.04);
			z-index: -1;
			@include lg {
				transform: translate(20px,20px) translateZ(-20px) scale(1.04); }
			@include sm {
				transform: translate(15px,15px) translateZ(-10px) scale(1); } } }

	.js-tilt-glare {
		border-radius: 50%;
		transform: translateZ(31px) scale(0.938); }

	.block-reveal {
		&__block {
			top: 4%;
			width: 101%; } } }

@keyframes avatar_animation {
	0% {
		transform: perspective(1000px) rotateX(0deg) rotateY(-5.64deg) scale3d(1, 1, 1);
		will-change: transform; }
	100% {
		transform: perspective(1000px) rotateX(0deg) rotateY(6deg) scale3d(1, 1, 1);
		will-change: transform; } }
