@import "sass/variables.sass";
@import "sass/mixins.sass";

.scroll-btn {
	&--next {
		position: absolute;
		bottom: 5vh;
		left: 50%;
		transform: translateX(-50%);
		@include xl {
			bottom: 3.5vh; }
		@include lg {
			bottom: 10px; }

		.scroll-btn {
			&__icon {
				order: 3;
				img {
					transform: rotate(180deg) translateY(6px); } }
			&__dot {
				order: 2;
				margin-bottom: 15px;
				@include xl {
					margin-bottom: 11px; } }
			&__text {
				order: 1;
				margin-bottom: 10px;
				@include xl {
					margin-bottom: 7px; } } }

		&:hover,
		&:focus {
			// .scroll-btn
			// 	&__icon
			// 		transform: translateY(5px)
			// 	&__dot
 } } }			// 		transform: translateY(3px)
