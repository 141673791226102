// Font face
@mixin font_face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;

		@if $asset-pipeline == true {
			src: font-url('#{$file-path}.eot');
			src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.woff') format('woff'), font-url('#{$file-path}.ttf') format('truetype'); }
		@else {
			src: url('#{$file-path}.eot');
			src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'); } } }


// Horizontal gradient - from left to right
@mixin gradient_x($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x; }

// Vertical gradient - from top to bottom
@mixin gradient_y($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x; }


// Templates
@mixin def_text {
	font-size: 18px;
	line-height: 23px;
	font-weight: 500; }

@mixin big_text {
	font-size: 24px;
	line-height: 31px;
	font-weight: 500; }

@mixin height_fill_with_footer {
	padding-top: 123px;
	height: calc(100vh - 123px);
	@include xxl {
		padding-top: 103px;
		height: calc(100vh - 103px); }
	@include xl {
		padding-top: 78px;
		height: calc(100vh - 78px); }
	@include sm {
		padding-top: 67px;
		height: calc(100vh - 67px); } }

// Media queries
// max-width
@mixin xxxl {
  @media only screen and (max-width: $xxxl - 1) {
    @content; } }

@mixin xxl {
  @media only screen and (max-width: $xxl - 1) {
    @content; } }

@mixin xl {
  @media only screen and (max-width: $xl - 1) {
    @content; } }

@mixin lg {
  @media only screen and (max-width: $lg - 1) {
    @content; } }

@mixin md {
  @media only screen and (max-width: $md - 1) {
    @content; } }

@mixin sm {
  @media only screen and (max-width: $sm - 1) {
    @content; } }

@mixin xsm {
  @media only screen and (max-width: $xsm - 1) {
    @content; } }


// min-width
@mixin min_xxxl {
  @media only screen and (min-width: $xxxl) {
    @content; } }

@mixin min_xxl {
  @media only screen and (min-width: $xxl) {
    @content; } }

@mixin min_xl {
  @media only screen and (min-width: $xl) {
    @content; } }

@mixin min_lg {
  @media only screen and (min-width: $lg) {
    @content; } }

@mixin min_md {
  @media only screen and (min-width: $md) {
    @content; } }

@mixin min_sm {
  @media only screen and (min-width: $sm) {
    @content; } }



// Media queries
// max-height
@mixin h800 {
  @media only screen and (max-height: $h800) {
    @content; } }
