@import "sass/variables.sass";
@import "sass/mixins.sass";

.block-reveal {
	position: relative;
	display: inline-block;
	&__element {
		opacity: 0; }
	&__block {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 1; } }
