@import "sass/variables.sass";
@import "sass/mixins.sass";


////////// Menu
.menu {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $c0;
	transform: translateX(100%);
	z-index: 10;

	&__list {
		text-align: center; }
	&__item {
		opacity: 0;
		margin-bottom: 20px;
		@include xxl {
			margin-bottom: 13px; }

		&:last-child {
			margin-bottom: 0; } }

	&__link {
		position: relative;
		font-size: 48px;
		line-height: 62px;
		font-weight: 700;
		color: $c5;
		letter-spacing: 1px;
		transition: color .6s ease;
		@include xxxl {
			font-size: 44px;
			line-height: 58px; }
		@include xxl {
			font-size: 38px;
			line-height: 52px; }
		@include lg {
			font-size: 32px;
			line-height: 44px; }
		@include md {
			font-size: 30px;
			line-height: 41px;
			font-weight: 600; }
		@include xsm {
			font-size: 26px;
			line-height: 31px; }
		&:before {
			content: '';
			position: absolute;
			bottom: 40px;
			right: -16px;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background: transparent;
			transition: all 0.4s cubic-bezier(0.18, 0.89, 0.25, 1.39); }
		&:hover,
		&--active {
			color: $c1;
			&:before {
				transform: translateY(27px);
				background: $c1; } } }


	&__hovered {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 371px;
		font-size: 288px;
		line-height: 369px;
		font-weight: 700;
		color: $c2;
		text-align: center;
		white-space: nowrap;
		opacity: 0;
		z-index: -1;
		@include xxxl {
			height: 270px;
			font-size: 254px;
			line-height: 270px; }
		@include xxl {
			height: 210px;
			font-size: 194px;
			line-height: 210px; }
		@include lg {
			display: none; } } }
