@import "sass/variables.sass";
@import "sass/mixins.sass";
@import "bootstrap/scss/mixins/_grid.scss";

.project-add-mobile-imgs {
	padding: 40px 0 70px;
	@include lg {
		padding: 20px 0 60px; }
	@include md {
		padding: 10px 0 40px; }

	&__row {
		@include make-row();
		justify-content: center;
		@include sm {
			margin-left: -10px;
			margin-right: -10px; } }
	&__col {
		@include make-col-ready();
		@include make-col(3);
		@include lg {
			@include make-col(6); }
		@include sm {
			padding-left: 10px;
			padding-right: 10px; }
		@include xsm {
			@include make-col(12); } }

	&__title {
		text-align: center; }

	&__img {
		position: relative;
		max-width: 100%;
		margin: 0 auto 30px;
		background: darken($c2, 9%);
		border-radius: 20px;
		border: 6px solid $c2;
		box-shadow: 2px 10px 20px rgba($c2, .1);
		transition: all .4s ease;
		overflow: hidden;
		z-index: 1;
		@include xl {
			border-width: 5px;
			border-radius: 12px; }
		@include sm {
			border-width: 4px;
			border-radius: 9px;
			margin: 0 auto 20px; }
		@include xsm {
			max-width: 80%; }
		&:after {
			content: 'Loading ...';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: .5;
			z-index: -1; }
		&:hover {
			transform: translateY(-2px);
			box-shadow: 2px 20px 40px rgba($c2, .5); }
		img {
			display: block;
			border-radius: 14px;
			transition: all .4s ease;
			opacity: 0;
			@include xl {
				border-radius: 5px; }
			@include sm {
				border-radius: 2px; } } } }
