@import "sass/variables.sass";
@import "sass/mixins.sass";

.error-indicator {
	display: flex;
	align-items: center;
	flex-direction: column;

	&-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 200px; }

	&__emoji {
		display: flex;
		align-items: center;
		width: 75px;
		height: 75px;
		font-size: 60px;
		line-height: 60px;
		margin-bottom: 15px;
		overflow: hidden;
		@include xxl {
			width: 65px;
			height: 65px;
			font-size: 50px;
			line-height: 50px;
			margin-bottom: 11px; } }

	&__title {
		color: $c1;
		margin: 0 auto 3px !important; }
	&__desc {} }
